import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Divider } from "antd";
import moment from "moment";

import useWorkTimesByDate from "../../hooks/useWorkTimesByDate.hook";

import { LoadingIcon, RefreshIcon } from "../CustomIcons/CustomIcons.component";
import CustomAvatar from "../CustomAvatar/CustomAvatar";

import { startPopulateWorkTimes } from "../../actions/work-times.action";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectOfficeState } from "../../reducers/office.reducer";
import { selectCurrentWorkDayState } from "../../reducers/work-day.reducer";
import { formatDuration } from "../../utils/time-conversion.util";
import { getInitials } from "../../utils/string.util";
import {
  requestOffice,
  requestOfficeFailed,
  requestOfficeSuccess,
  startPopulateOffice,
} from "../../actions/office.action";
import { dispatchError } from "../../utils/error.util";

import ws from "../../sockets/websockets";
import { getNextCalendarScheduleService } from "../../services/third-party-calendar.service";
import { workDayDateFormat } from "../../constants/constants";

/**
 * Summary is a component with title and a list of items.
 * each item contains a title and a description.
 * it's used to represent today or next workday's summary
 */
const Summary = ({
  title,
  items,
  loading = false,
  empty = false,
  emptyText,
  RefreshSection,
}: {
  title: string;
  items: { title: string; desc: string }[];
  loading?: boolean;
  empty?: boolean;
  emptyText?: string;
  RefreshSection?: React.FC;
}) => {
  const itemList = items.map((item) => (
    <div key={item.desc} className="Office__summary-item">
      <span className="Office__summary-time">{item.title}</span>
      <span className="Office__summary-desc">{item.desc}</span>
    </div>
  ));

  return (
    <div className="Office__summary">
      <div className="Office__summary-header">
        <h2>{title}</h2>

        {RefreshSection && <RefreshSection />}
      </div>
      {loading && <LoadingIcon className="Office__summaries__loading" />}

      {!loading && empty && (
        <div className="Office__summary-content">
          <span className="Office__empty">{emptyText}</span>
        </div>
      )}

      {!loading && !empty && (
        <div className="Office__summary-content">
          {items.length > 0 ? (
            itemList
          ) : (
            <span className="Office__empty"></span>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Office component shows the summary page of a user, including today's work hours
 * and next workday's overview. It use "work-time" redux state to populate today's info
 */
const Office = () => {
  const dispatch = useDispatch();
  const { data: office, loading: officeLoading } = useSelector(
    selectOfficeState
  );
  const {
    data: {
      avatar: userAvatar,
      display_name: userDisplayName,
      what_i_do: role,
    },
    loading: userDetailsLoading,
    updatedKeys,
  } = useSelector(selectUserDetailsState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const [calendarLoading, setCalendarLoading] = React.useState(false);

  const avatarLoading = updatedKeys.includes("avatar") && userDetailsLoading;

  const [
    currentDateWorkTimes,
    currentDateWorkTimesLoading,
  ] = useWorkTimesByDate(currentWorkDayDate);

  const isLoading = officeLoading || calendarLoading;

  const synchronizeCalendarEntries = React.useCallback(
    async (messageEventData: {
      event: "calendar_entries_synced";
      meta: { data: CalendarScheduleArray; user: AccountUser["id"] };
    }) => {
      try {
        const { event, meta } = messageEventData;
        if (event === "calendar_entries_synced" && meta.data.length > 0) {
          let nextShift = {
            lastUpdated: "",
            dateTime: "",
            taskTime: 0,
            sessions: 0,
          };

          // sort so earliest schedule comes first
          const sortedCalendarSchedule = meta.data.sort(
            (a: CalendarScheduleObject, b: CalendarScheduleObject) => {
              const keyA = a.start_datetime,
                keyB = b.start_datetime;
              if (moment(keyA).isBefore(keyB)) return -1;
              if (moment(keyA).isAfter(keyB)) return 1;
              return 0;
            }
          );

          const nextWorkDay = moment(
            sortedCalendarSchedule[0].start_datetime
          ).format(workDayDateFormat);

          const updatedNextShift = sortedCalendarSchedule
            .filter(
              ({ start_datetime }: CalendarScheduleObject) =>
                moment(start_datetime).format(workDayDateFormat) === nextWorkDay
            )
            .reduce((acc, cur) => {
              return {
                lastUpdated: cur.last_updated_at,
                dateTime: cur.start_datetime,
                taskTime: cur.work_time_seconds + acc.taskTime,
                sessions: acc.sessions + 1,
              };
            }, nextShift);

          setCalendarLoading(false);
          dispatch(
            requestOfficeSuccess({
              todaySessions: office.todaySessions,
              nextWorkdaySessions: updatedNextShift.sessions,
              nextWorkdayWorkTime: updatedNextShift.taskTime,
              nextWorkdayDate: updatedNextShift.dateTime,
              lastUpdated: updatedNextShift.lastUpdated,
            })
          );
        }
      } catch (e: unknown) {
        dispatch(requestOfficeFailed((e as Error).message));
        dispatchError({
          e,
          title: "Synchronize calendar entries error",
        });
      }
    },
    [dispatch, office]
  );

  React.useEffect(() => {
    if (currentWorkDayDate)
      dispatch(startPopulateWorkTimes(currentWorkDayDate));
  }, [currentWorkDayDate, dispatch]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      const messageEventData = JSON.parse(event.data);
      synchronizeCalendarEntries(messageEventData);
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [ws, synchronizeCalendarEntries]);

  const todayItems = [
    {
      title: formatDuration(currentDateWorkTimes.work_time, "seconds"),
      desc: "Work time",
    },
    {
      title: formatDuration(currentDateWorkTimes.break_time, "seconds"),
      desc: "Break time",
    },
    {
      title: office.todaySessions > 0 ? office.todaySessions.toString() : "-",
      desc:
        office.todaySessions > 1 || office.todaySessions <= 0
          ? "Sessions"
          : "Session",
    },
  ];

  const nextWorkdayItems = [
    {
      title: moment(office.nextWorkdayDate).format("ddd, MMMM D"),
      desc: "Date",
    },
    {
      title: formatDuration(office.nextWorkdayWorkTime, "seconds"),
      desc: "Work time",
    },
    {
      title: office.nextWorkdaySessions.toString(),
      desc: office.nextWorkdaySessions > 1 ? "Sessions" : "Session",
    },
  ];

  const handleClickRefresh = async () => {
    setCalendarLoading(true);
    try {
      await getNextCalendarScheduleService(); // called to update the db so webhook event returns accurate data
    } catch (e: unknown) {
      dispatchError({
        e,
        title: "Get next calendar schedule error",
      });
    }
  };

  const RefreshSection = () =>
    React.useMemo(() => {
      return (
        <>
          <div>
            {!isLoading && (
              <span className="Office__summary-header__timestamp">
                Updated: {moment(office.lastUpdated).format("YYYY-MM-DD H:mm")}
              </span>
            )}
            <div className="Office__summary-header__refresh-wrapper">
              {isLoading ? (
                <LoadingIcon className="Office__summary-header__refresh-btn" />
              ) : (
                <RefreshIcon
                  className="Office__summary-header__refresh-btn"
                  onClick={handleClickRefresh}
                />
              )}
            </div>
          </div>
        </>
      );
    }, [office.lastUpdated, isLoading]);

  return (
    <section className="Office">
      <header className="Office__header">Office</header>
      <Divider className="Office__divider" />
      <div className="Office__info-container">
        {avatarLoading && <Avatar size={48} icon={<LoadingIcon />} />}
        {!avatarLoading && (
          <>
            <CustomAvatar
              size={48}
              src={userAvatar}
              fallbackText={getInitials(userDisplayName)}
            />
            <div className="Office__info">
              <span className="Office__name">{userDisplayName}</span>
              <span className="Office__title">{role}</span>
            </div>
          </>
        )}
      </div>
      <div className="Office__summaries">
        <Summary
          title="today's hours"
          items={todayItems}
          loading={officeLoading}
        />
        <Summary
          title="next workday"
          items={nextWorkdayItems}
          loading={isLoading}
          empty={office.nextWorkdaySessions <= 0}
          emptyText="There are no next workdays scheduled."
          RefreshSection={RefreshSection}
        />
      </div>
    </section>
  );
};

export default Office;
